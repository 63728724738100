<script setup lang="ts">
const noHeader = useAttrs()['no-header'] !== undefined;
</script>

<template>
    <div class="body-container">
        <header v-if="!noHeader" class="main-header">
            <img :src="`/flavors/${$flavorManager.flavor}/logo.svg`" alt="">
        </header>
        <main :class="noHeader ? 'p-5' : 'p-3'">
            <slot></slot>
        </main>
    </div>
</template>
